import { createBrowserRouter } from 'react-router-dom';
import PageNotFound from '../components/page-not-found';
import Layout from '../layout';
import Dashboard from '../screens/dashboard';
import Admin from '../screens/admin';
import Payment from '../screens/payment';
import PaymentList from '../screens/payment/listing';

// Authorization
import Login from '../screens/auth/login';
import ResetPassword from '../screens/auth/reset-password';

import Customers from '../screens/customers'

// configuration
import Configuration from '../screens/configuration';


// settings
import Settings from '../screens/settings';
import Profile from '../screens/settings/profile';
import ChangePassword from '../screens/settings/change-password';
import LostFoundItems from '../screens/lost-found-items';
import LostItems from '../screens/lost-found-items/lost-items';
import FoundItems from '../screens/lost-found-items/found-items';
import AccessControl from '../screens/access-control';
import Users from '../screens/access-control/users';
import Roles from '../screens/access-control/roles';
import Questionnaire from '../screens/questionnaire';
import Chat from '../screens/chat';
import Items from '../screens/items-category';
import IndividualUsers from '../screens/customers/individual-users';
import BusinessUsers from '../screens/customers/business-users';
import ResolvedItems from '../screens/lost-found-items/resolved-items';

export default createBrowserRouter([
  {
    path: '',
    element: <Login />,
    errorElement: <PageNotFound />,
  },
  {
    path: 'reset-password',
    element: <ResetPassword />,
    errorElement: <PageNotFound />,
  },
  {
    element: <Layout />,
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'customers',
        element: <Customers />,
        errorElement: <PageNotFound />,
        children:[
          {
            path: 'individual-users',
            element: <IndividualUsers />,
            errorElement: <PageNotFound />,
          },
          {
            path: 'business-users',
            element: <BusinessUsers />,
            errorElement: <PageNotFound />,
          }
        ]
      },
      {
        path: 'items-category',
        element: <Items />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'questionnaire',
        element: <Questionnaire />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'chat',
        element: <Chat />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'lost-found-items',
        element: <LostFoundItems />,
        errorElement: <PageNotFound />,
        children: [
          {
            path: 'lost-items',
            element: <LostItems />,
            errorElement: <PageNotFound />,
          },
          {
            path: 'found-items',
            element: <FoundItems />,
            errorElement: <PageNotFound />,
          },
          {
            path: 'resolved-items',
            element: <ResolvedItems />,
            errorElement: <PageNotFound />,
          },
        ],
      },
      {
        path: 'access-control',
        element: <AccessControl />,
        errorElement: <PageNotFound />,
        children: [
          {
            path: 'users',
            element: <Users />,
            errorElement: <PageNotFound />,
          },
          {
            path: 'roles',
            element: <Roles />,
            errorElement: <PageNotFound />,
          },
        ],
      },
      {
        path: 'payment',
        element: <Payment />,
        errorElement: <PageNotFound />,
        children: [
          {
            path: ':duration',
            element: <PaymentList />,
            errorElement: <PageNotFound />,
          },
        ],
      },
      {
        path: 'configurations',
        element: <Configuration />,
        errorElement: <PageNotFound />
      },
      {
        path: 'admin-users',
        element: <Admin />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'settings',
        element: <Settings />,
        errorElement: <PageNotFound />,
        children: [
          {
            path: 'profile',
            element: <Profile />,
            errorElement: <PageNotFound />,
          },
          {
            path: 'change-password',
            element: <ChangePassword />,
            errorElement: <PageNotFound />,
          },
        ],
      },
    ],
  },
]);

import { FC, useReducer } from 'react';
import { WarningDialog } from '../../../../components';
import { TextField, Typography } from '@mui/material';
import { getStaticTexts } from '../../../../constants';

interface props {
  isOpen: boolean;
  isDisabled?: boolean;
  onClose: () => void;
}

const Approval: FC<props> = ({ isOpen = true, onClose }) => {
  const staticText = getStaticTexts('en');
  const approveUserReducee = (state: any, action: any) => {
    switch (action.type) {
      case 'UPDATE_FIELDS':
        return { ...state, ...action.payload };
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const [state, dispatch] = useReducer(approveUserReducee, {
    showRejectButton: true,
    rejectRequest: false,
  });

  const onReject = () => {
    dispatch({ type: 'UPDATE_FIELDS', payload: { rejectRequest: !state.rejectRequest, showRejectButton: false } });
  };

  return (
    <WarningDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={() => {}}
      showRejectButton={state.showRejectButton}
      onReject={onReject}
      title={staticText.approveUsersRequest}
    >
      {!state.rejectRequest && (
        <Typography
          variant="body1"
          paddingY="8px"
        >
          {staticText.areYouSureYouWantToApproveThisUser}
        </Typography>
      )}

      {state.rejectRequest && (
        <TextField
          label="Explain Reason"
          sx={{ margin: '12px 0' }}
          variant="outlined"
          multiline
          rows={4}
          fullWidth
        />
      )}
    </WarningDialog>
  );
};

export default Approval;

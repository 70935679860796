import './style.scss';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables, ArcElement, Tooltip, Legend } from 'chart.js';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
Chart.register(...registerables, ArcElement, Tooltip, Legend);

interface props {
    label?: any;
    data: any;
    isStacked?: boolean;
    height?: number;
}

export const BarGraph:FC<props> = ({ label, data, isStacked = false, height = 400 }) => {
    return (
        <Box className='box-container' sx={{ height: height }}>
            <Typography variant='h6'>
                {label}
            </Typography>
            <div>
                {
                    isStacked
                        ?
                        <Bar
                            data={data}
                            width={100}
                            height={height}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        stacked: true,
                                    }
                                }
                            }}
                        />
                        :
                        <Bar
                            data={data}
                            width={100}
                            height={340}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                            }}
                        />
                }

            </div>
        </Box>
    )
}

import { CLEAR_USER_PERMISSIONS, SET_USER_PERMISSIONS } from '../../actions';

const initialState = {
  role: 'admin',
  permissions: [],
};

export const permissionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_USER_PERMISSIONS:
      const { role, permissions } = action.payload;
      state.role = role;
      state.permissions = permissions;
      break;

    case CLEAR_USER_PERMISSIONS:
      state.role = '';
      state.permissions = [];
      break;
  }
  return state;
};

import { FC, ChangeEvent, useEffect, useReducer } from 'react';
import { CustomDialog, CustomTable } from '../../../../components';
import { Box, Checkbox, Grid2, TextField } from '@mui/material';

interface props {
  isOpen: boolean;
  type: 'new' | 'edit' | 'view';
  isDisabled?: boolean;
  onClose: () => void;
}

const Manage: FC<props> = ({ isOpen, type, isDisabled = true, onClose }) => {
  isDisabled = type === 'view' ? true : false;

  const manageUserReducer = (state: any, action: any) => {
    switch (action.type) {
      case 'LOADED':
        return { ...state, loading: false, list: action.list };
      case 'UPDATE_FIELDS':
        return { ...state, ...action.payload };
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const [state, dispatch] = useReducer(manageUserReducer, {
    info: {
      name: '',
      email: '',
    },
    list: [],
    selectAll: [],
  });

  const createRow = (selectAll: string[], user: any, index: number) => {
    const canRead = (
      <Checkbox
       // onChange={(e) => onMultiDelete(e, selectAll, (index + 1).toString())}
        checked={selectAll.includes((index + 1).toString()) ? true : false}
      />
    );

    const canWrite = (
      <Checkbox
      //  onChange={(e) => onMultiDelete(e, selectAll, (index + 1).toString())}
        checked={selectAll.includes((index + 1).toString()) ? true : false}
      />
    );

    const canDelete = (
      <Checkbox
       // onChange={(e) => onMultiDelete(e, selectAll, (index + 1).toString())}
        checked={selectAll.includes((index + 1).toString()) ? true : false}
      />
    );

    return {
      title: user.title,
      read: canRead,
      write: canWrite,
      delete: canDelete
    };
  };

  useEffect(() => {
    if (type === 'view' || type === 'edit') {
      dispatch({
        type: 'UPDATE_FIELDS',
        payload: {
          ...state,
          info: {
            ...state.info,
            name: 'hulk',
            email: 'hulk@relinns.com',
            phone: '12345678909',
            preference: 'OFFER',
          },
        },
      });
    }

    return () => {
      return dispatch({
        type: 'UPDATE_FIELDS',
        payload: {
          info: {
            ...state.info,
            name: '',
            email: '',
            phone: '',
          },
        },
      });
    };
  }, [type]);

  useEffect(() => {
    const data = [
      {
        title: 'User',
      },
      {
        title: 'Business User',
      },
      {
        title: 'Items Category',
      },
      {
        title: 'Questionnaire',
      },
      {
        title: 'Chat',
      },
      {
        title: 'Lost/Found Items',
      },
      {
        title: 'Configuration'
      },
    ];

    const list = data.map((user, index) =>
      createRow(state.selectAll, user, index)
    );

    dispatch({ type: 'LOADED', list });
  }, [state.selectAll, state.selectAll.length]);

  const onEdit = () => {};

  const handleSelectAll = () => {};

  const columns = [
    {
      id: 'title',
      label: 'Title'
    },
    {
      id: 'read',
      label: 'Read',
    },
    {
      id: 'write',
      label: 'Write',
    },
    {
      id: 'delete',
      label: 'Delete',
    }
  ];

  const uploadImage = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files?.length) {
      console.log({ 'size in MB': Math.round(files[0].size / 1024 / 1024) });
    }
  };

  const onPageChange = () => {};

  return (
    <CustomDialog
      title={'User Detail'}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onClose}
      disabled={isDisabled}
      onEdit={onEdit}
    >
      <Grid2
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid2
          component="div"
          size={6}
        >
          <TextField
            label="Name"
            name="name"
            value={state.info.name}
            disabled={isDisabled}
          />
        </Grid2>
        <Grid2
          component="div"
          size={6}
        >
          <TextField
            label="Description"
            name="description"
            value={state.info.email}
            disabled={isDisabled}
          />
        </Grid2>
      </Grid2>
      <Box marginTop="10px">
        <Box paddingY='8px' fontWeight={600}>Permissions</Box>
        <CustomTable
          columns={columns}
          rows={state.list}
          height="auto"
          errorMessage="Add permissions to see here"
        />
      </Box>
    </CustomDialog>
  );
};

export default Manage;

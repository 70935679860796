import { ChangeEvent, useEffect, useReducer } from 'react';
import { Box, Checkbox, FormControl, IconButton, MenuItem, Select, Switch, Tooltip, Typography } from '@mui/material';
import { Header, CustomTable, WarningDialog } from '../../components';
import DeleteIcon from '@mui/icons-material/Delete';
import { withPermission } from '../../components/hoc';
import { getStaticTexts, PERMISSIONS } from '../../constants';
import { ChatStates } from '../../interfaces';

const Chat = () => {
  const staticTexts = getStaticTexts('en');
  const chatReducer = (state: ChatStates, action: any): ChatStates => {
    switch (action.type) {
      case 'LOADING':
        return { ...state, loading: true };
      case 'LOADED':
        return { ...state, loading: false, list: action.list };
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const [state, dispatch] = useReducer(chatReducer, {
    deleteWarning: false,
    multiDeleteWarning: false,
    _user: '',
    list: [],
    selectAll: [],
    loading: false,
  });

  useEffect(() => {
    const data = [
      {
        name: 'Hulk',
        email: 'hulk@relinns.com',
        phone: '+91 1234567890',
      },
      {
        name: 'Thor',
        email: 'thor@relinns.com',
        phone: '+91 1234567890',
      },
      {
        name: 'Dr Strange',
        email: 'drstrange@relinns.com',
        phone: '+91 1234567890',
      },
    ];

    const list = data.map((user, index) =>
      createRow(state.selectAll, user, index, handleManage, handleDelete, handleMultipleSelect)
    );

    dispatch({ type: 'LOADED', list });
  }, [state.selectAll, state.selectAll.length]);

  const handleSearch = (e: ChangeEvent<HTMLElement>) => {};

  const onPageChange = (e: ChangeEvent<unknown>) => {};

  const handleManage = (type: 'new' | 'edit' | 'view') => {
    //   return {
    //     ...prevState,
    //     manage: {
    //       ...prevState.manage,
    //       isOpen: !prevState.manage.isOpen,
    //       type,
    //     },
    //   };
    // });
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      // payload = state.list.map((ele) => ele.id.toString());
    } else {
      payload = [];
    }

    // setState((prevState) => {
    //   return {
    //     ...prevState,
    //     selectAll: payload,
    //   };
    // });
  };

  const handleMultipleSelect = (e: ChangeEvent<HTMLInputElement>, selectAll: string[], id: string) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      payload = selectAll;
      payload.push(id);
    } else {
      payload = selectAll.filter((ele) => ele !== id);
    }

    // setState((prevState) => {
    //   return {
    //     ...prevState,
    //     selectAll: payload,
    //   };
    // });
  };

  const handleDelete = (_user: string = '') => {
    // setState((prevState) => {
    //   return {
    //     ...prevState,
    //     deleteWarning: !prevState.deleteWarning,
    //     _user,
    //   };
    // });
  };

  const handleMultiDelete = () => {
    // setState((prevState) => {
    //   return {
    //     ...prevState,
    //     multiDeleteWarning: !prevState.multiDeleteWarning,
    //   };
    // });
  };

  const onDelete = () => {};

  const columns = [
    {
      id: 'all',
      label: (
        <Checkbox
          onChange={handleSelectAll}
          checked={state.selectAll.length && state.list.length === state.selectAll.length ? true : false}
        />
      ),
    },
    {
      id: 'id',
      label: staticTexts.serialNumber,
    },
    {
      id: 'name',
      label: staticTexts.name,
    },
    {
      id: 'email',
      label: staticTexts.email,
    },
    {
      id: 'phone',
      label: staticTexts.phone,
    },
    {
      id: 'status',
      label: staticTexts.status,
    },
    {
      id: 'action',
      label: staticTexts.action,
    },
  ];

  const statusFilter = [
    {
      label: staticTexts.allStatus,
      value: 'ALL',
    },
    {
      label: staticTexts.active,
      value: 'ACTIVE',
    },
    {
      label: staticTexts.inactive,
      value: 'INACTIVE',
    },
  ];

  const createRow = (selectAll: string[], user: any, index: number, onEdit: any, onDelete: any, onMultiDelete: any) => {
    const all = (
      <Checkbox
        onChange={(e) => onMultiDelete(e, selectAll, (index + 1).toString())}
        checked={selectAll.includes((index + 1).toString()) ? true : false}
      />
    );

    const name = (
      <Typography
        color="primary"
        variant="body1"
        onClick={() => onEdit('view')}
      >
        {user.name}
      </Typography>
    );

    const status = <Switch defaultChecked />;

    const action = (
      <>
        <Tooltip title={staticTexts.delete}>
          <IconButton
            onClick={() => onDelete(index)}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );

    return {
      all,
      id: index + 1,
      name,
      email: user.email,
      phone: user.phone,
      status,
      action,
    };
  };

  return (
    <>
      {/* Add Data  */}
      <Header
        searchPlaceholder={staticTexts.searchByUserNameEmailAndPhone}
        onSearch={handleSearch}
        onBtnClick={() => handleManage('new')}
        onDelete={handleMultiDelete}
        isDeleteDisable={state.selectAll.length ? false : true}
        searchInput
      >
        <FormControl
          className="ml-2"
          size="small"
        >
          <Select
            size="small"
            defaultValue="ALL"
          >
            {statusFilter.map((filter) => (
              <MenuItem value={filter.value}>{filter.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={state.list}
          height="calc(100vh - 193px)"
          errorMessage={staticTexts.addUserToSeeDataHere}
          pagination={{
            page: 3,
            totalPages: 5,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title={staticTexts.deleteUser}
        description={staticTexts.areYouSureYouWantToDeleteThisUser}
      />

      {/* Multi Delete Data  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMultiDelete()}
        onConfirm={onDelete}
        title={staticTexts.deleteUsers}
        description={staticTexts.areYouSureYouWantToDeleteThisUsers}
      />
    </>
  );
};

export default withPermission(Chat, PERMISSIONS.VIEW_CHAT);

import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { FC, MouseEvent, ReactNode } from 'react';

interface props {
  isOpen: boolean;
  title: string;
  description?: string;
  children?: ReactNode;
  showRejectButton?: boolean;
  onClose: (e: MouseEvent<HTMLElement>) => void;
  onConfirm: (e: MouseEvent<HTMLElement>) => void;
  onReject?: (e: MouseEvent<HTMLElement>) => void;
}

export const WarningDialog: FC<props> = ({ isOpen, title, description, children,showRejectButton=false, onClose, onConfirm, onReject }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
       <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
       {children}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        {showRejectButton && (
          <Button
            variant="outlined"
            onClick={onReject}
            autoFocus
            color="error"
          >
            Reject
          </Button>
        )}
        <Button
          onClick={onConfirm}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import './style.scss';
import { useState } from 'react';
import { Divider, List, ListItemIcon, ListItemText, Avatar, Typography } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import Logo from '../assets/images/logo.png';
import {
  Dashboard as DashboardIcon,
  ArrowBackIos as ArrowBackIosIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  ManageAccounts as ManageAccountsIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  Settings as SettingsIcon,
  ExpandLess as ExpandLessIcon,
  ExitToApp as ExitToAppIcon,
  Category,
  QuestionAnswer,
  Chat,
  HelpOutline,
  Security,
  Payment as PaymentIcon,
} from '@mui/icons-material';
import ListItem from './components/layout-list-item';
import { WarningDialog } from '../components';
import { PERMISSIONS } from '../constants';
import { PermissionGuard } from '../guards';
import { useSelector } from 'react-redux';

const Layout = () => {
  const location = useLocation();
  const [state, setState] = useState({
    collapse: false,
    menuOption: false,
    logoutWarning: false,
  });

  const userPermissions = useSelector((state: any) => state.permissions);

  const changeSidebarSize = () => {
    const leftSide = document.getElementById('left') as HTMLElement;
    const rightSide = document.getElementById('right') as HTMLElement;
    const logo = document.getElementById('logo') as HTMLElement;
    const collapseArrow = document.getElementById('collapse') as HTMLElement;
    const collapseText = document.getElementsByClassName('collapse-text') as any;
    const collapseListItem = document.getElementsByClassName('collapse-ListItem') as any;
    const nav_items_box = document.getElementsByClassName('nav-items-box') as any;

    const isCollapsed = !state.collapse;

    leftSide.style.width = isCollapsed ? '60px' : '200px';
    rightSide.style.marginLeft = isCollapsed ? 'calc(60px + 16px)' : 'calc(200px + 16px)';
    logo.style.display = isCollapsed ? 'none' : 'flex';
    collapseArrow.style.margin = isCollapsed ? '20px 0px' : '0px';
    collapseArrow.style.width = isCollapsed ? '40px' : '45px';
    collapseArrow.style.height = isCollapsed ? '40px' : '37px';
    collapseArrow.style.transform = isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)';
    nav_items_box[0].style.height = isCollapsed ? 'calc(100% - 95px)' : 'calc(100% - 110px)';

    Array.from(collapseText).forEach((item: any) => {
      item.style.display = isCollapsed ? 'none' : 'initial';
    });

    Array.from(collapseListItem).forEach((item: any) => {
      item.style.paddingLeft = '18px';
    });

    setState((prevState) => ({
      ...prevState,
      collapse: isCollapsed,
    }));
  };

  const handleProfileMenu = () => {
    setState((prevState) => ({
      ...prevState,
      menuOption: !prevState.menuOption,
    }));
  };

  const handleLogout = () => {
    setState((prevState) => ({
      ...prevState,
      logoutWarning: !prevState.logoutWarning,
    }));
  };

  const navItems = [
    {
      label: 'Dashboard',
      navigateLink: '/dashboard',
      Icon: DashboardIcon,
      requiredPermission: PERMISSIONS.VIEW_DASHBOARD,
    },
    {
      label: 'Customers',
      navigateLink: '/customers/individual-users',
      Icon: SupervisedUserCircleIcon,
      requiredPermission: PERMISSIONS.VIEW_CUSTOMERS,
    },
    {
      label: 'Items Category',
      navigateLink: '/items-category',
      Icon: Category,
      requiredPermission: PERMISSIONS.VIEW_ITEMS_CATEGORY,
    },
    {
      label: 'Questionnaire',
      navigateLink: '/questionnaire',
      Icon: QuestionAnswer,
      requiredPermission: PERMISSIONS.VIEW_QUESTIONNAIRE,
    },
    {
      label: 'Chat',
      navigateLink: '/chat',
      Icon: Chat,
      requiredPermission: PERMISSIONS.VIEW_CHAT,
    },
    {
      label: 'Lost/Found Items',
      navigateLink: '/lost-found-items/lost-items',
      Icon: HelpOutline,
      requiredPermission: PERMISSIONS.VIEW_LOST_FOUND_ITEMS,
    },
    {
      label: 'Access Control',
      navigateLink: '/access-control/users',
      Icon: Security,
      requiredPermission: PERMISSIONS.VIEW_ACCESS_CONTROL,
    },
    // {
    //   label: 'Payment',
    //   navigateLink: '/payment/all',
    //   Icon: PaymentIcon,
    //   requiredPermission: PERMISSIONS.VIEW_PAYMENT,
    // },
    {
      label: 'Configurations',
      navigateLink: '/configurations',
      Icon: ManageAccountsIcon,
      requiredPermission: PERMISSIONS.EDIT_CONFIGURATIONS,
    },
    // {
    //   label: 'Admin User',
    //   navigateLink: '/admin-users',
    //   Icon: AdminPanelSettingsIcon,
    //   requiredPermission: PERMISSIONS.VIEW_ADMIN_USERS,
    // },
  ];

  return (
    <>
      {/* left sidebar  */}
      <div
        id="left"
        className="left-side-nav"
      >
        <div className="center">
          <div
            id="logo"
            className="logo-box"
          >
            <img
              src={Logo}
              alt="logo"
            />
          </div>
          <div
            id="collapse"
            className="collapse-arrow"
            onClick={changeSidebarSize}
          >
            <ArrowBackIosIcon className="collapse-arrow-icon w-100 h-100" />
          </div>
        </div>

        <div className="nav-items-box">
          {/* navigation buttons  */}
          <div style={{ overflowX: 'scroll' }}>
            <List>
              {navItems.map((item, index) => {
                const { label, navigateLink, Icon } = item;

                return (
                  <PermissionGuard
                    key={index}
                    permission={userPermissions}
                  >
                    <ListItem
                      navigateLink={navigateLink}
                      label={label}
                      Icon={Icon}
                    />
                  </PermissionGuard>
                );
              })}
            </List>
          </div>

          {/* profile buttons */}
          <div>
            <Divider className="divider-color" />
            <div>
              <div className={`profile-transition ${state.menuOption ? 'margin-top-30' : 'profile-buttons-hide'}`}>
                <ListItem
                  label="Settings"
                  navigateLink="/settings/profile"
                  Icon={SettingsIcon}
                />

                <div
                  className="profileButtonWrap"
                  id="logout-icon"
                  onClick={handleLogout}
                >
                  <ListItemIcon className="navIcons">
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="collapse-text"
                    primary={'Logout'}
                    classes={{ primary: 'listItem-text' }}
                  />
                </div>
              </div>

              <div
                id="profile"
                className={state.menuOption ? 'rotate-icon-down active-profile profile' : 'rotate-icon-up profile'}
                onClick={handleProfileMenu}
              >
                <div className="profile-name-box">
                  <Avatar className="profile-avatar">M</Avatar>
                  <div className="name collapse-text">Mayank...</div>
                </div>
                <ExpandLessIcon className="collapse-text" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* right side content  */}
      <div
        id="right"
        className="right-side-content"
      >
        <div className="top-nav">
          <Typography variant="h5">{location.pathname.split('/')[1]}</Typography>
        </div>
        <Outlet />
      </div>

      {/* Logout warning  */}
      <WarningDialog
        isOpen={state.logoutWarning}
        onClose={handleLogout}
        onConfirm={handleLogout}
        title="Logout"
        description="Are you sure do you want to logout?"
      />
    </>
  );
};

export default Layout;

import './style.scss';
import { Box, Typography, TextField, Button } from '@mui/material';
import Logo from '../../../assets/images/logo.png';

const ResetPassword = () => {
  return (
    <div
      className="center"
      id="forgot-password"
    >
      <Box className="border forgot-password-box">
        <img
          width={100}
          height={100}
          alt="logo"
          src={Logo}
          style={{objectFit:'contain'}}
        />

        <Typography
          className="my-4"
          variant="h4"
        >
          Reset Password
        </Typography>
        <div className="">
          <TextField
            label="Password"
            className="email-adornment"
            sx={{ marginBottom: '8px' }}
          />
          <TextField
            label="Confirm Password"
            className="password-adornment"
            sx={{ marginBottom: '8px' }}
          />
        </div>
        <Button sx={{width:'90%', marginTop:'8px'}}>
           Reset Password
        </Button>
      </Box>
    </div>
  );
};

export default ResetPassword;

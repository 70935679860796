import './style.scss'
import { useState } from 'react';
import { Box, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import Tag from './components/tags';
import {BarGraph, CustomTable} from '../../components';
import { Link } from 'react-router-dom';
import { withPermission } from '../../components/hoc';
import { PERMISSIONS } from '../../constants';


const columns = [
  {
    id: 'id',
    label: 'S No.'
  },
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'email',
    label: 'Email'
  },
  {
    id: 'phone',
    label: 'Phone'
  }
];

const rows = [
  {
    id: 1,
    name: 'mayank',
    email: 'mayank2@efd.sdfs',
    phone: '+91 1234567890'
  },
  {
    id: 2,
    name: 'mayank',
    email: 'mayank2@efd.sdfs',
    phone: '+91 1234567890'
  },
  {
    id: 3,
    name: 'mayank',
    email: 'mayank2@efd.sdfs',
    phone: '+91 1234567890'
  },
  {
    id: 4,
    name: 'mayank',
    email: 'mayank2@efd.sdfs',
    phone: '+91 1234567890'
  },
  {
    id: 1,
    name: 'mayank',
    email: 'mayank2@efd.sdfs',
    phone: '+91 1234567890'
  },
  {
    id: 1,
    name: 'mayank',
    email: 'mayank2@efd.sdfs',
    phone: '+91 1234567890'
  },
  {
    id: 1,
    name: 'mayank',
    email: 'mayank2@efd.sdfs',
    phone: '+91 1234567890'
  },
  {
    id: 1,
    name: 'mayank',
    email: 'mayank2@efd.sdfs',
    phone: '+91 1234567890'
  },
  {
    id: 1,
    name: 'mayank',
    email: 'mayank2@efd.sdfs',
    phone: '+91 1234567890'
  },
  {
    id: 1,
    name: 'mayank',
    email: 'mayank2@efd.sdfs',
    phone: '+91 1234567890'
  },
  {
    id: 1,
    name: 'mayank',
    email: 'mayank2@efd.sdfs',
    phone: '+91 1234567890'
  },
]

const Dashboard = () => {
  const [state, setState] = useState({
    selectedTag: 'Total Users',
    totalCount: {
      items: 12
    }
  });

  const handleTagsChange = async (name: string) => {
    setState(prevState => {
      return {
        ...prevState,
        selectedTag: name
      }
    });
  };

  return (
    <div>
      <Box className='tags' marginBottom='30px'>
        <Typography className='head' variant='body1' marginBottom='10px'>Overview</Typography>
        <Box className='overview-tabs' display='flex' border='1px solid #dee2e6'>
          <Tag
            name='Total Users'
            select={state.selectedTag}
            count={12}
            onClick={handleTagsChange}
          />
          <Tag
            name='Lost Items'
            select={state.selectedTag}
            count={12}
            onClick={handleTagsChange}
          />
          <Tag
            name='Found Items'
            select={state.selectedTag}
            count={12}
            onClick={handleTagsChange}
          />
        </Box>
      </Box>


      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box className="center" marginBottom='10px' justifyContent='space-between'>
            <Typography className='head' variant='body1'>
              Analytics (Last Week)
            </Typography>
            <div className="date-picker position-relative">
              <FormControl fullWidth>
                <Select
                  variant="outlined"
                  size='small'
                  defaultValue={7}
                >
                  <MenuItem value={0} disabled>Choose Duration</MenuItem>
                  <MenuItem value={7}>Last Week</MenuItem>
                  <MenuItem value={15}>Last Month</MenuItem>
                  <MenuItem value={30}>Last Year</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Box>
          <BarGraph
            data={{

              labels: ['a', 'b', 'c', 'd', 'e', 'f', 'g'],
              datasets: [
                {
                  label: "Candidates",
                  data: [12, 3, 5, 6, 6, 55, 7],
                  backgroundColor: ["rgba(158, 229, 193, 0.3)"],
                },
              ],
            }}
            height={390}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className="center" marginBottom='18px' justifyContent='space-between'>
            <Typography className="recent-applications m-0" variant='h6'>Recent Customer</Typography>
            <Link
              className="more-details underline-none"
              to='/customers/individual-users'
            >
              More Details
            </Link>
          </Box>
          <CustomTable
            columns={columns}
            rows={rows}
            height={390 + 20}
          />
        </Grid>
      </Grid>


    </div>
  )
}

export default withPermission(Dashboard, PERMISSIONS.VIEW_DASHBOARD);

import React from 'react';
import { useSelector } from 'react-redux';

/**
 * Author: Mayank
 * Date: 5 Nov 2024
 * Summary: A higher-order component for conditional rendering based on user permissions.
 * Description:
 * - The `withPermission` higher-order component wraps a given component and conditionally renders it
 * based on the user's permissions and role. It retrieves the current user's permissions and role
 * from the Redux store.
 * - If the user has an 'admin' role, the wrapped component is rendered
 * without additional checks, as admins have full access. For other roles, it checks if the
 * required permission is included in the user's permissions.
 * - If the permission is granted, the component is rendered; otherwise, an "Access Denied" message is displayed. This HOC
 * simplifies permission management across the application, enabling easy reuse of permission
 * checks for multiple components.
 */

interface WithPermissionProps {
  [key: string]: any;
}

export const withPermission = (Component: React.ComponentType<WithPermissionProps>, requiredPermission: string) => {
  const WrappedComponent: React.FC<WithPermissionProps> = (props) => {
    const { permissions, role } = useSelector((state: any) => state.permissions);

    // Admins have all permissions, so no need to check further if the role is admin
    if (role === 'admin' || permissions.includes(requiredPermission)) {
      return <Component {...props} />;
    }

    return <p>Access Denied</p>; // Or redirect to a "No Access" page
  };

  return WrappedComponent;
};

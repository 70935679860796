import { Switch } from '@mui/material';
import React from 'react'
import { PERMISSIONS } from '../../constants';
import { withPermission } from '../../components/hoc';

const Configuration = () => {
  return (
    <div>
      Admin Verification  <Switch defaultChecked/>
    </div>
  )
}

export default withPermission(Configuration, PERMISSIONS.VIEW_CONFIGURATION);

import './style.scss';
import { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid2, TextField, Typography, Link } from '@mui/material';
import Logo from '../../../assets/images/logo.png';

const Login = () => {
  const navigate = useNavigate();

  const onLogin = (event: SyntheticEvent) => {
    event.preventDefault();
    navigate('/dashboard');
  };

  const [loginInfo, setLoginInfo] = useState({
    email: '',
    password: '',
    forgotPassword: false,
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setLoginInfo((prevLoginInfo) => ({
      ...prevLoginInfo,
      [name]: value,
    }));
  };

  return (
    <div className="container center">
      <div className="login-box">
        <form
          className="h-100"
          onSubmit={onLogin}
        >
          <Grid2
            className="h-100"
            container
          >
            <Grid2
              className="h-100"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              size={{ xs: 12, md: 6 }}
            >
              <img
                alt="Login"
                id="left-img"
                src={Logo}
                width={'50%'}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <div className="credential-box center h-100">
                <div className="mb-5 text-center">
                  <Typography variant="h4">Welcome to</Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                  >
                    Lqeet
                  </Typography>
                </div>
                <TextField
                  label="Email"
                  className="mb-3"
                  name="email"
                  value={loginInfo.email}
                  onChange={handleChange}
                />
                {!loginInfo.forgotPassword && (
                  <TextField
                    label="Password"
                    name="password"
                    className="mb-3"
                    value={loginInfo.password}
                    onChange={handleChange}
                  />
                )}

                <Link
                  className="mb-3 cursor-pointer"
                  width={'100%'}
                  underline="none"
                  color="secondary"
                  textAlign="left"
                  onClick={() => {
                    setLoginInfo((prev) => ({
                      ...prev,
                      forgotPassword: !prev.forgotPassword,
                    }));
                  }}
                >
                  {loginInfo.forgotPassword ? 'Back to login' : 'Forgot Password?'}
                </Link>

                <Button
                  type="submit"
                  className="mb-3"
                  size="medium"
                  fullWidth
                >
                  {loginInfo.forgotPassword ? 'Send' : 'Login'}
                </Button>
              </div>
            </Grid2>
          </Grid2>
        </form>
      </div>
    </div>
  );
};

export default Login;

import React from 'react';

const Details = () => {
  return (
    <>
      <div className="detail-container ">
        <div className="detail-card">
          <div className="row">
            <div className="col-xs-12 user-image">
              <img
                src="https://www.thetimes.co.uk/imageserver/image/%2Fmethode%2Ftimes%2Fprod%2Fweb%2Fbin%2Fc47f6830-9292-11ed-b04f-b9bf191ef388.jpg?crop=5879%2C3307%2C0%2C306&resize=300"
                alt=""
              />
            </div>
            {/* Using hardcoded value for now .. will optimize later */}
            <div className="col-md-6 col-xs-12 user-detail">
              <span>
                <b>User Name</b>
              </span>
              <address className="text-wrap">
                {' '}
                Relinns Technologies, 303, C-184,&nbsp; Sahibzada Ajit Singh Nagar,&nbsp; Ropar,&nbsp; Rupnagar,&nbsp;
                Punjab,&nbsp; 140308
              </address>

              <span>Gender : </span>
              <span>Male</span>

              <br />
              <span>Phone : </span>
              <span>9874563210</span>

              <br />
              <span>Registration no. : </span>
              <span>REG77845612323232</span>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;

import { ChangeEvent, useState, useEffect } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { CustomTable, Header, WarningDialog } from '../../../components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Manage from './manage';
import { AccessControlStates } from '../../../interfaces';

const Users = () => {
  const [state, setState] = useState<AccessControlStates>({
    manage: {
      type: 'new',
      isOpen: false,
    },
    deleteWarning: false,
    multiDeleteWarning: false,
    _user: '',
    list: [],
    selectAll: [],
  });

  useEffect(() => {
    const data = [
      {
        name: 'Hulk',
        email: 'hulk@relinns.com',
        phone: '+91 1123356545',
      },
      {
        name: 'Thor',
        email: 'thor@relinns.com',
        phone: '+91 1123356545',
      },
      {
        name: 'Loki',
        email: 'loki@relinns.com',
        phone: '+91 43543543534',
      },
    ];

    const list = data.map((user, index) =>
      createRow(state.selectAll, user, index, handleManage, handleDelete, handleMultipleSelect)
    );
    setState((prevState: any) => {
      return {
        ...prevState,
        list: list
      };
    });
  }, [state.selectAll, state.selectAll.length]);

  const handleSearch = (e: ChangeEvent<HTMLElement>) => {};

  const onPageChange = (e: ChangeEvent<unknown>) => {};

  const handleManage = (type: 'new' | 'edit' | 'view') => {
    setState((prevState) => {
      return {
        ...prevState,
        manage: {
          ...prevState.manage,
          isOpen: !prevState.manage.isOpen,
          type,
        },
      };
    });
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      payload = state.list.map((ele) => ele.id.toString());
    } else {
      payload = [];
    }

    setState((prevState) => {
      return {
        ...prevState,
        selectAll: payload,
      };
    });
  };

  const handleMultipleSelect = (e: ChangeEvent<HTMLInputElement>, selectAll: string[], id: string) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      payload = selectAll;
      payload.push(id);
    } else {
      payload = selectAll.filter((ele) => ele !== id);
    }

    setState((prevState) => {
      return {
        ...prevState,
        selectAll: payload,
      };
    });
  };

  const handleDelete = (_user: string = '') => {
    setState((prevState) => {
      return {
        ...prevState,
        deleteWarning: !prevState.deleteWarning,
        _user,
      };
    });
  };

  const handleMultiDelete = () => {
    setState((prevState) => {
      return {
        ...prevState,
        multiDeleteWarning: !prevState.multiDeleteWarning,
      };
    });
  };

  const onDelete = () => {};

  const columns = [
    {
      id: 'all',
      label: (
        <Checkbox
          onChange={handleSelectAll}
          checked={state.selectAll.length && state.list.length === state.selectAll.length ? true : false}
        />
      ),
    },
    {
      id: 'id',
      label: 'S No.',
    },
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'email',
      label: 'Email',
    },
    {
      id: 'phone',
      label: 'Phone',
    },
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'action',
      label: 'Action',
    },
  ];

  const createRow = (selectAll: string[], user: any, index: number, onEdit: any, onDelete: any, onMultiDelete: any) => {
    const all = (
      <Checkbox
        onChange={(e) => onMultiDelete(e, selectAll, (index + 1).toString())}
        checked={selectAll.includes((index + 1).toString()) ? true : false}
      />
    );

    const name = (
      <Typography
        className="cursor-pointer"
        color="primary"
        variant="body1"
        onClick={() => onEdit('view')}
      >
        {user.name}
      </Typography>
    );

    const status = <Switch defaultChecked />;

    const action = (
      <>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => onEdit('edit')}
            color="primary"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => onDelete(index)}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );

    return {
      all,
      id: index + 1,
      name,
      email: user.email,
      phone: user.phone,
      status,
      action,
    };
  };

  return (
    <>
      {/* Add Data  */}
      <Header
        searchPlaceholder="Search by user name, email and phone"
        onSearch={handleSearch}
        onBtnClick={() => handleManage('new')}
        onDelete={handleMultiDelete}
        isDeleteDisable={state.selectAll.length ? false : true}
        searchInput
        btnText='Add New User'
      >
        <FormControl
          className="ml-2"
          size="small"
        >
          <Select
            size="small"
            defaultValue="all"
          >
            <MenuItem value="all">All Status</MenuItem>
            <MenuItem value="ACTIVE">Active</MenuItem>
            <MenuItem value="INACTIVE">Inactive</MenuItem>
          </Select>
        </FormControl>
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={state.list}
          height="calc(100vh - 193px)"
          errorMessage="Add user to see the data here"
          pagination={{
            page: 3,
            totalPages: 5,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Manage Data  */}
      <Manage
        isOpen={state.manage.isOpen}
        type={state.manage.type}
        onClose={() => handleManage(state.manage.type)}
      />

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete User"
        description="Are you sure do you want to delete this user?"
      />

      {/* Multi Delete Data  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMultiDelete()}
        onConfirm={onDelete}
        title="Delete Users"
        description="Are you sure do you want to delete all selected users?"
      />
    </>
  );
};

export default Users;

import { FC, ChangeEvent, useEffect, useReducer } from 'react';
import { CustomDialog } from '../../../../components';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Details from '../details';

interface props {
  isOpen: boolean;
  type: 'new' | 'edit' | 'view';
  isDisabled?: boolean;
  onClose: () => void;
}

const Manage: FC<props> = ({ isOpen, type, isDisabled, onClose }) => {
  isDisabled = type === 'view' ? true : false;

  const manageUserReducer = (state: any, action: any) => {
    switch (action.type) {
      case 'UPDATE_FIELDS':
        return { ...state, ...action.payload };
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const [state, dispatch] = useReducer(manageUserReducer, {
    info: {
      name: '',
      email: '',
    },
  });

  useEffect(() => {
    if (type === 'view' || type === 'edit') {
      dispatch({
        type: 'UPDATE_FIELDS',
        payload: {
          ...state,
          info: {
            ...state.info,
            name: 'hulk',
            email: 'hulk@relinns.com',
            phone: '12345678909'
          },
        },
      });
    }

    return () => {
      return dispatch({
        type: 'UPDATE_FIELDS',
        payload: {
          info: {
            ...state.info,
            name: '',
            email: '',
            phone: ''
          },
        },
      });
    };
  }, [type]);

  const onEdit = () => {};

  const uploadImage = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files?.length) {
      console.log({ 'size in MB': Math.round(files[0].size / 1024 / 1024) });
    }
  };

  console.log(state, 'state');
  return (
    <CustomDialog
      title={'User Detail'}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onClose}
      disabled={isDisabled}
      onEdit={onEdit}
    >
      <Box>
        {type === 'view' && <Details />}

        {(type === 'edit' || type === 'new') && (
          <>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                xs={12}
                md={12}
              >
                <Box className="center my-5">
                  <Box
                    height="100px"
                    width="100px"
                    className="img-circle"
                    aria-label="upload picture"
                    component="label"
                  >
                    <img
                      alt="Profile-Pic"
                      src="https://www.thetimes.co.uk/imageserver/image/%2Fmethode%2Ftimes%2Fprod%2Fweb%2Fbin%2Fc47f6830-9292-11ed-b04f-b9bf191ef388.jpg?crop=5879%2C3307%2C0%2C306&resize=300"
                    />
                    <div className="edit-img ">Edit</div>
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={uploadImage}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
              >
                <TextField
                  label="Name"
                  name="name"
                  value={state.info.name}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
              >
                <TextField
                  label="Email"
                  name="email"
                  value={state.info.email}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
              >
                <TextField
                  label="Phone"
                  name="phone"
                  value={state.info.phone}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
              ></Grid>
            </Grid>
          </>
        )}
        {/* <SetLocation  isOpen= {locationDialog} onClose={()=> setLocationDialog(false)} /> */}
      </Box>
    </CustomDialog>
  );
};

Manage.defaultProps = {
  isDisabled: true,
};

export default Manage;

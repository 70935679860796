export const PERMISSIONS = {
  // Dashboard permissions
  VIEW_DASHBOARD: 'dashboard.read',

  // Customers permissions
  ADD_CUSTOMERS: 'customers.add',
  VIEW_CUSTOMERS: 'customers.read',
  EDIT_CUSTOMERS: 'customers.write',
  DELETE_CUSTOMERS: 'customers.delete',

  // Items Category permissions
  VIEW_ITEMS_CATEGORY: 'items.read',

  // Questionnaire permissions
  VIEW_QUESTIONNAIRE: 'questionnaire.read',

  // Chat permissions
  VIEW_CHAT: 'chat.read',

  // Lost/Found Items permissions
  VIEW_LOST_FOUND_ITEMS: 'lostFoundItems.read',

  // Access Control permissions
  VIEW_ACCESS_CONTROL: 'accessControl.read',
  EDIT_ACCESS_CONTROL: 'accessControl.write',
  ASSIGN_ROLES: 'accessControl.assignRoles',
  REMOVE_ROLES: 'accessControl.removeRoles',
  VIEW_USER_PERMISSIONS: 'accessControl.viewUserPermissions',
  EDIT_USER_PERMISSIONS: 'accessControl.editUserPermissions',

  // Payment permissions
  VIEW_PAYMENT: 'payment.read',

  // Configurations permissions
  VIEW_CONFIGURATION: 'configurations.read',
  EDIT_CONFIGURATIONS: 'configurations.edit',

  // Admin User permissions
  VIEW_ADMIN_USERS: 'adminUsers.read',
};

import { useState, SyntheticEvent, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { withPermission } from '../../components/hoc';
import { PERMISSIONS } from '../../constants';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const redirectionLinks = ['users', 'roles'];
const tabs = [
  { label: 'Users', index: 0 },
  { label: 'Roles', index: 1 },
];

const AccessControl = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setValue(redirectionLinks.indexOf(location.pathname.split('/')[2]));
  }, [location.pathname]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(redirectionLinks[newValue]);
  };

  return (
    <Box width="100%">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs.map((tab, key) => (
            <Tab
              key={key}
              className="tabs-space"
              label={tab.label}
              {...a11yProps(tab.index)}
            />
          ))}
        </Tabs>
      </Box>
      <Outlet />
    </Box>
  );
};

export default withPermission(AccessControl, PERMISSIONS.VIEW_DASHBOARD);

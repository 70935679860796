import { ChangeEvent } from 'react';
import './style.scss';
import { Box, Grid, TextField } from '@mui/material';

const Profile = () => {
  const uploadImage = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if(files?.length) {
      console.log({'size in MB': Math.round(files[0].size / 1024 / 1024)});
    }
  }

  return (
    <div id='profile-info'>
      <Box className='center my-5'>
        <Box height='100px' width='100px' className="img-circle" aria-label="upload picture" component="label">
          <img alt='Profile-Pic' src='https://tinyurl.com/2vbpc6pd' />
          <div className='edit-img '>Edit</div>
          <input hidden accept="image/*" type="file" onChange={uploadImage} />
        </Box>
      </Box>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <TextField
            label='Name'
            variant="outlined"
            value='Mayank'
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label='Email'
            variant="outlined"
            value='mayank@relinns.com'
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label='Phone'
            variant="outlined"
            value='1234567890'
            disabled
          />
        </Grid>

      </Grid>
    </div>
  )
}

export default Profile;

import './style.scss';
import { FC, ChangeEvent } from 'react';
import Pagination from '@mui/material/Pagination';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Button,
  TableBody,
  styled,
  TableCell,
  tableCellClasses,
  Typography,
  Box,
} from '@mui/material';
import TableChartIcon from '@mui/icons-material/TableChart';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f7f7f7',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface props {
  columns: any;
  rows: any;
  height: number | string;
  errorMessage?: string;
  pagination?: {
    page: number;
    totalPages: number;
  };
  onPageChange?: (e: ChangeEvent<unknown>) => void;
}
interface IColumns {
  id: string;
  label: string;
  type?: string;
  action?: string[];
  format?: any;
  align?: any;
}
export const CustomTable: FC<props> = ({
  columns,
  rows,
  height,
  pagination,
  onPageChange,
  errorMessage = 'Add some data to see',
}) => {
  return (
    <>
      <TableContainer sx={{ border: '1px solid #e3e3e3', height: height, borderRadius: '2px' }}>
        <Table
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              {columns.map((column: any, index: number) => (
                <StyledTableCell
                  key={index}
                  align={column.align}
                  style={{ whiteSpace: 'nowrap', minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any, index: number) => {
              return (
                <StyledTableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                >
                  {columns.map((column: IColumns, i: number) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        style={{ whiteSpace: 'nowrap' }}
                        key={i}
                        align={column.align}
                      >
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                        {column.id === 'action' &&
                          column.action?.map((key: string) => (
                            <Button
                              size="small"
                              className="button-delete"
                            >
                              <svg
                                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                data-testid="DeleteIcon"
                              >
                                <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
                              </svg>
                            </Button>
                          ))}
                      </TableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
        {!rows?.length && (
          <div className="no-data">
            <TableChartIcon
              fontSize="large"
              className="error-icon"
            />
            <Typography className="noData-title">No data to show!</Typography>
            <Typography className="noData-sub-title">{errorMessage}</Typography>
          </div>
        )}
      </TableContainer>
      <Box marginTop="10px">
        {pagination?.page && (
          <Pagination
            count={pagination.totalPages}
            page={pagination.page}
            onChange={onPageChange}
            color="primary"
          />
        )}
      </Box>
    </>
  );
};

import englishTexts from './en/en.json';

const getStaticTexts = (language: string) => {
  switch (language) {
    case 'en':
      return englishTexts;
    default:
      return englishTexts;
  }
}

export { getStaticTexts };

import { ChangeEvent, useEffect, useReducer } from 'react';
import { Autocomplete, Box, Checkbox, FormControl, IconButton, MenuItem, Select, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { Header, CustomTable, WarningDialog } from '../../components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Manage from './manage';
import { PermissionGuard } from '../../guards';
import { useSelector } from 'react-redux';
import { QuestionnaireStates } from '../../interfaces';
import { getStaticTexts } from '../../constants';

const Questionnaire = () => {
  const staticTexts = getStaticTexts('en');
  const top100Films = [
    { label: 'Loki', year: 1994 },
    { label: 'Hulk', year: 1972 },
    { label: 'Tony', year: 1974 },
  ];
  const questionnaireReducer = (state: QuestionnaireStates, action: any): QuestionnaireStates => {
    switch (action.type) {
      case 'LOADING':
        return { ...state, loading: true };
      case 'LOADED':
        return { ...state, loading: false, list: action.list };
      case 'UPDATE_FIELDS':
        return { ...state, ...action.payload };
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const [state, dispatch] = useReducer(questionnaireReducer, {
    manage: {
      type: 'new',
      isOpen: false,
    },
    deleteWarning: false,
    multiDeleteWarning: false,
    _user: '',
    list: [],
    selectAll: [],
    loading: false,
  });
  const permissions = useSelector((state: any) => state.permissions);

  useEffect(() => {
    const data = [
      {
        question: 'Can you describe the item in detail?',
        category: 'Electronic',
      },
      {
        question: 'Where and when did you last see the item?',
        category: 'Furniture',
      },
      {
        question: 'Can you recall any distinguishing wear and tear or damage?',
        category: 'Automotive',
      },
    ];

    const list = data.map((user, index) =>
      createRow(state.selectAll, user, index, handleManage, handleDelete, handleMultipleSelect)
    );

    dispatch({ type: 'LOADED', list });
  }, [state.selectAll, state.selectAll.length]);

  const handleSearch = (e: ChangeEvent<HTMLElement>) => {};

  const onPageChange = (e: ChangeEvent<unknown>) => {};

  const handleManage = (type: 'new' | 'edit' | 'view') => {
    dispatch({
      type: 'UPDATE_FIELDS',
      payload: {
        manage: {
          ...state.manage,
          type,
          isOpen: !state.manage.isOpen,
        },
      },
    });
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      // payload = state.list.map((ele) => ele.id.toString());
    } else {
      payload = [];
    }

    // setState((prevState) => {
    //   return {
    //     ...prevState,
    //     selectAll: payload,
    //   };
    // });
  };

  const handleMultipleSelect = (e: ChangeEvent<HTMLInputElement>, selectAll: string[], id: string) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      payload = selectAll;
      payload.push(id);
    } else {
      payload = selectAll.filter((ele) => ele !== id);
    }

    // setState((prevState) => {
    //   return {
    //     ...prevState,
    //     selectAll: payload,
    //   };
    // });
  };

  const handleDelete = (_user: string = '') => {
    // setState((prevState) => {
    //   return {
    //     ...prevState,
    //     deleteWarning: !prevState.deleteWarning,
    //     _user,
    //   };
    // });
  };

  const handleMultiDelete = () => {
    // setState((prevState) => {
    //   return {
    //     ...prevState,
    //     multiDeleteWarning: !prevState.multiDeleteWarning,
    //   };
    // });
  };

  const onDelete = () => {};

  const columns = [
    {
      id: 'all',
      label: (
        <Checkbox
          onChange={handleSelectAll}
          checked={state.selectAll.length && state.list.length === state.selectAll.length ? true : false}
        />
      ),
    },
    {
      id: 'id',
      label: staticTexts.serialNumber,
    },
    {
      id: 'question',
      label: staticTexts.question,
    },
    {
      id: 'category',
      label: staticTexts.category,
    },
    {
      id: 'status',
      label: staticTexts.status,
    },
    {
      id: 'action',
      label: staticTexts.action,
    },
  ];

  const createRow = (selectAll: string[], user: any, index: number, onEdit: any, onDelete: any, onMultiDelete: any) => {
    const all = (
      <Checkbox
        onChange={(e) => onMultiDelete(e, selectAll, (index + 1).toString())}
        checked={selectAll.includes((index + 1).toString()) ? true : false}
      />
    );

    const status = <Switch defaultChecked />;

    const action = (
      <>
        <PermissionGuard permission={permissions}>
          <Tooltip title={staticTexts.edit}>
            <IconButton
              onClick={() => onEdit('edit')}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </PermissionGuard>
        <PermissionGuard permission={permissions}>
          <Tooltip title={staticTexts.delete}>
            <IconButton
              onClick={() => onDelete(index)}
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </PermissionGuard>
      </>
    );

    return {
      all,
      id: index + 1,
      question: user.question,
      category: user.category,
      status,
      action,
    };
  };

  return (
    <>
      {/* Add Data  */}
      <Header
        onBtnClick={() => handleManage('new')}
        onDelete={handleMultiDelete}
        isDeleteDisable={state.selectAll.length ? false : true}
        btnText={staticTexts.addNewQuestions}
      >
        <FormControl
          className="ml-2"
          size="medium"
        >
          <Autocomplete
            multiple
            sx={{ width: '200px' }}
            options={top100Films}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  style={{ padding: '5px', marginRight: '5px' }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={staticTexts.allCategory}
                placeholder={staticTexts.searchByCategoryName}
              />
            )}
            renderTags={() => null}
            clearIcon={null}
          />
        </FormControl>
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={state.list}
          height="calc(100vh - 193px)"
          errorMessage="Add user to see the data here"
          pagination={{
            page: 3,
            totalPages: 5,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Manage Data  */}
      <Manage
        isOpen={state.manage.isOpen}
        type={state.manage.type}
        onClose={() => handleManage(state.manage.type)}
      />

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete User"
        description="Are you sure do you want to delete this user?"
      />

      {/* Multi Delete Data  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMultiDelete()}
        onConfirm={onDelete}
        title="Delete Users"
        description="Are you sure do you want to delete all selected users?"
      />
    </>
  );
};

export default Questionnaire;

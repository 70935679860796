import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { CustomDialog } from '../../../components';

interface props {
    isOpen: boolean;
    type: 'new' | 'edit' | 'view'
    isDisabled?: boolean;
    onClose: () => void;
}

const Manage: FC<props> = ({ isOpen, type, isDisabled, onClose }) => {
    isDisabled = type === 'view' ? true : false;
    const [state, setState] = useState({
        info: {
            image: '',
            name: '',
            email: '',
            phone: '',
            role: '',
            status: ''
        }
    });

    useEffect(() => {
        if (type === 'view' || type === 'edit') {
            setState(prevState => {
                return {
                    ...prevState,
                    info: {
                        ...prevState.info,
                        image: '',
                        name: 'Tony',
                        email: 'Tony@relinns.com',
                        phone: '+91 678687686',
                        role: 'OFFER',
                        status: 'ACTIVE'
                    }
                }
            });
        }

        return () => {
            return setState(prevState => {
                return {
                    ...prevState,
                    info: {
                        ...prevState.info,
                        image: '',
                        name: '',
                        email: '',
                        phone: '',
                        role: '',
                        status: ''
                    }
                }
            });
        }
    }, [type]);

    const onEdit = () => {

    }

    const uploadImage = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files?.length) {
            console.log({ 'size in MB': Math.round(files[0].size / 1024 / 1024) });
        }
    }

    return (
        <CustomDialog
            title={'Manage'}
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={onClose}
            disabled={isDisabled}
            onEdit={onEdit}
        >
            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={12}>
                        <Box className='center my-5'>
                        <Box height='100px' width='100px' className="img-circle" aria-label="upload picture" component="label">
                                <img alt='Profile-Pic' src='https://www.thetimes.co.uk/imageserver/image/%2Fmethode%2Ftimes%2Fprod%2Fweb%2Fbin%2Fc47f6830-9292-11ed-b04f-b9bf191ef388.jpg?crop=5879%2C3307%2C0%2C306&resize=300' />
                                <div className='edit-img '>Edit</div>
                                <input hidden accept="image/*" type="file" onChange={uploadImage} />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label='Name'
                            name='name'
                            value={state.info.name}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label='Email'
                            name='email'
                            value={state.info.email}
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label='Phone'
                            name='phone'
                            value={state.info.phone}
                            disabled={isDisabled}
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                        <FormControl size='small' fullWidth>
                            <InputLabel id="demo-simple-select-label">Role</InputLabel>
                            <Select
                                size='small'
                                value={state.info.role}
                                label='Role'
                            >
                                <MenuItem disabled>Select</MenuItem>
                                <MenuItem value='OFFER'>Offer</MenuItem>
                                <MenuItem value='FINDER'>Finder</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <FormControl size='small' fullWidth>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                size='small'
                                value={state.info.status}
                                label='Status'
                            >
                                <MenuItem disabled>Select</MenuItem>
                                <MenuItem value='ACTIVE'>Active</MenuItem>
                                <MenuItem value='INACTIVE'>In-Active</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </CustomDialog>
    )
}

Manage.defaultProps = {
    isDisabled: true
}

export default Manage;
import { FC, useEffect, useState } from 'react';
import { CustomDialog } from '../../../components';
import { Box, FormControl, Grid2, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { getStaticTexts } from '../../../constants';
import { ManageItemsCategory } from '../../../interfaces/items-category';

const Manage: FC<ManageItemsCategory> = ({ isOpen, type, isDisabled = true, onClose }) => {
  isDisabled = type === 'view' ? true : false;
  const [state, setState] = useState({
    info: {
      question: '',
      category: '',
    },
  });
  const staticTexts = getStaticTexts('en');

  useEffect(() => {
    if (type === 'view' || type === 'edit') {
      setState((prevState) => {
        return {
          ...prevState,
          info: {
            ...prevState.info,
            question: 'Question Test',
            category: 'Category 1',
          },
        };
      });
    }

    return () => {
      return setState((prevState) => {
        return {
          ...prevState,
          info: {
            ...prevState.info,
            registration_id: '',
            trip_id: '',
            driver_name: '',
            email: '',
            phone: '',
            total_distance: '',
          },
        };
      });
    };
  }, [type]);

  return (
    <CustomDialog
      title={staticTexts.questionDetails}
      disabled={isDisabled}
      isOpen={isOpen}
      onClose={onClose}
      btnText='Add Question'
    >
      <Box>
        <Grid2
          container
          spacing={4}
        >
          <Grid2 size={{ xs: 12, md: 6 }}>
            <TextField
              label={staticTexts.question}
              name="question"
              value={state.info.question}
              disabled={isDisabled}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{staticTexts.category}</InputLabel>
              <Select
                size="small"
                value="male"
                label="gender"
                disabled={isDisabled}
              >
                <MenuItem disabled>Select</MenuItem>
                <MenuItem value="male">Category 1</MenuItem>
                <MenuItem value="female">Category 2</MenuItem>
              </Select>
            </FormControl>
          </Grid2>
        </Grid2>
      </Box>
    </CustomDialog>
  );
};

export default Manage;

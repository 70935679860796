import { FC, useEffect, useState } from 'react';
import { CustomDialog } from '../../../components';
import { Box, Grid2, TextField } from '@mui/material';
import { getStaticTexts } from '../../../constants';
import { ManageItemsCategory } from '../../../interfaces/items-category';

const Manage: FC<ManageItemsCategory> = ({ isOpen, type, isDisabled = true, onClose }) => {
  isDisabled = type === 'view' ? true : false;
  const [state, setState] = useState({
    info: {
      categoryName: '',
      description: '',
    },
  });
  const staticTexts = getStaticTexts('en');

  useEffect(() => {
    if (type === 'view' || type === 'edit') {
      setState((prevState) => {
        return {
          ...prevState,
          info: {
            ...prevState.info,
            categoryName: 'Category Test',
            description: 'Some Description',
          },
        };
      });
    }

    return () => {
      return setState((prevState) => {
        return {
          ...prevState,
          info: {
            ...prevState.info,
            registration_id: '',
            trip_id: '',
            driver_name: '',
            email: '',
            phone: '',
            total_distance: '',
          },
        };
      });
    };
  }, [type]);

  return (
    <CustomDialog
      title={staticTexts.categoryDetail}
      disabled={isDisabled}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box>
        <Grid2
          container
          spacing={4}
        >
          <Grid2 size={{ xs: 12, md: 6 }}>
            <TextField
              label={staticTexts.categoryName}
              name="name"
              value={state.info.categoryName}
              disabled={isDisabled}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <TextField
              label={staticTexts.description}
              name="description"
              value={state.info.description}
              disabled={isDisabled}
            />
          </Grid2>
        </Grid2>
      </Box>
    </CustomDialog>
  );
};

export default Manage;

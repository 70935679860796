import { ChangeEvent, useEffect, useReducer } from 'react';
import { Header, CustomTable } from '../../../components';
import { Autocomplete, Box, Checkbox, FormControl, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import { getStaticTexts } from '../../../constants';
import { FoundItemsState } from '../../../interfaces';

const FoundItems = () => {
  const staticText = getStaticTexts('en');
  const top100Films = [
    { label: 'Loki', year: 1994 },
    { label: 'Hulk', year: 1972 },
    { label: 'Tony', year: 1974 },
  ];
  const lostItemReducer = (state: FoundItemsState, action: any) => {
    switch (action.type) {
      case 'LOADING': {
        return { loading: true, list: state.list, _item: state._item };
      }
      case 'LOADED': {
        return { loading: false, list: action.list, _item: state._item };
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  };

  const [state, dispatch] = useReducer(lostItemReducer, {
    _item: '',
    list: [],
    loading: false,
  });

  useEffect(() => {
    const data = [
      {
        name: 'Hulk',
        foundAt: 'Hellerup, Hovedstaden, Denmark',
        date: '25 Apr, 2023 11:01 PM',
        status: 'ACTIVE',
      },
      {
        name: 'Hulk',
        foundAt: 'Olympus',
        date: '25 Apr, 2023 11:01 PM',
        status: 'ACTIVE',
      },
      {
        name: 'Loki',
        foundAt: 'Asgard',
        date: '25 Apr, 2023 11:01 PM',
        status: 'INACTIVE',
      },
    ];

    const list = data.map((user, index) => createRow(user, index));
    dispatch({ type: 'LOADED', list });
  }, []);

  const handleSearch = (e: ChangeEvent<HTMLElement>) => {};

  const onPageChange = (e: ChangeEvent<unknown>) => {};

  const columns = [
    {
      id: 'id',
      label: staticText.itemId,
    },
    {
      id: 'name',
      label: staticText.itemName,
    },
    {
      id: 'foundAt',
      label: staticText.foundAt,
    },
    {
      id: 'status',
      label: staticText.status,
    },
    {
      id: 'date',
      label: staticText.date,
    },
  ];

  const createRow = (item: any, index: number) => {
    return {
      id: index + 1,
      name: (
        <Typography
          className="cursor-pointer"
          color="primary"
          variant="body1"
        >
          {item.name}
        </Typography>
      ),
      date: item.date,
      foundAt: item.foundAt,
      status: <Switch defaultChecked />,
    };
  };

  return (
    <>
      <Header
        searchPlaceholder={staticText.searchByItemName}
        onSearch={handleSearch}
        searchInput
      >
        <FormControl
          className="ml-2"
          size="small"
        >
          <Select
            size="small"
            defaultValue="all"
          >
            <MenuItem value="all">{staticText.allStatus}</MenuItem>
            <MenuItem value="ACTIVE">{staticText.active}</MenuItem>
            <MenuItem value="INACTIVE">{staticText.inactive}</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          className="ml-2"
          size="medium"
        >
          <Autocomplete
            multiple
            sx={{ width: '200px' }}
            options={top100Films}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  style={{ padding: '5px', marginRight: '5px' }}
                  checked={selected}
                />
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={staticText.allCategory}
                placeholder={staticText.searchByCategoryName}
              />
            )}
            renderTags={() => null}
            clearIcon={null}
          />
        </FormControl>
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={state.list}
          height="calc(100vh - 193px)"
          errorMessage={staticText.addItemsToSeeDataHere}
          pagination={{
            page: 3,
            totalPages: 5,
          }}
          onPageChange={onPageChange}
        />
      </Box>
    </>
  );
};

export default FoundItems;

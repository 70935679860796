import { ChangeEvent, useEffect, useReducer } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { Header, CustomTable, WarningDialog } from '../../../components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Manage from './manage';

interface state {
  deleteWarning: boolean;
  multiDeleteWarning: boolean;
  _user: string;
  list: {
    all: JSX.Element;
    id: number;
    name: JSX.Element;
    description: string;
    status: JSX.Element;
    action: JSX.Element;
  }[];
  selectAll: string[];
  loading: boolean;
  manage: {
    isOpen: boolean;
    type: 'new' | 'view' | 'edit';
  };
}

const Roles = () => {
  const rolesReducer = (state: state, action: any): state => {
    switch (action.type) {
      case 'LOADING':
        return { ...state, loading: true };
      case 'LOADED':
        return { ...state, loading: false, list: action.list };
      case 'UPDATE_FIELDS':
        return { ...state, ...action.payload };
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const [state, dispatch] = useReducer(rolesReducer, {
    deleteWarning: false,
    multiDeleteWarning: false,
    _user: '',
    list: [],
    selectAll: [],
    loading: false,
    manage: {
      isOpen: false,
      type: 'new',
    },
  });

  useEffect(() => {
    const data = [
      {
        name: 'Role 1',
        description: 'This is role 1 for testing',
      },
      {
        name: 'Role 2',
        description: 'This is role 2 for testing',
      },
      {
        name: 'Role 3',
        description: 'This is role 3 for testing',
      },
    ];

    const list = data.map((user, index) =>
      createRow(state.selectAll, user, index, handleManage, handleDelete, handleMultipleSelect)
    );

    dispatch({ type: 'LOADED', list });
  }, [state.selectAll, state.selectAll.length]);

  const handleSearch = (e: ChangeEvent<HTMLElement>) => {};

  const onPageChange = (e: ChangeEvent<unknown>) => {};

  const handleManage = (type: 'new' | 'edit' | 'view') => {
    dispatch({
      type: 'UPDATE_FIELDS',
      payload: {
        manage: {
          ...state.manage,
          type,
          isOpen: !state.manage.isOpen,
        },
      },
    });
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      // payload = state.list.map((ele) => ele.id.toString());
    } else {
      payload = [];
    }

    // setState((prevState) => {
    //   return {
    //     ...prevState,
    //     selectAll: payload,
    //   };
    // });
  };

  const handleMultipleSelect = (e: ChangeEvent<HTMLInputElement>, selectAll: string[], id: string) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      payload = selectAll;
      payload.push(id);
    } else {
      payload = selectAll.filter((ele) => ele !== id);
    }

    // setState((prevState) => {
    //   return {
    //     ...prevState,
    //     selectAll: payload,
    //   };
    // });
  };

  const handleDelete = (_user: string = '') => {
    // setState((prevState) => {
    //   return {
    //     ...prevState,
    //     deleteWarning: !prevState.deleteWarning,
    //     _user,
    //   };
    // });
  };

  const handleMultiDelete = () => {
    // setState((prevState) => {
    //   return {
    //     ...prevState,
    //     multiDeleteWarning: !prevState.multiDeleteWarning,
    //   };
    // });
  };

  const onDelete = () => {};

  const columns = [
    {
      id: 'all',
      label: (
        <Checkbox
          onChange={handleSelectAll}
          checked={state.selectAll.length && state.list.length === state.selectAll.length ? true : false}
        />
      ),
    },
    {
      id: 'id',
      label: 'S No.',
    },
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'description',
      label: 'Description',
    },
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'action',
      label: 'Action',
    },
  ];

  const statusFilter = [
    {
      label: 'All Status',
      value: 'ALL',
    },
    {
      label: 'Active',
      value: 'ACTIVE',
    },
    {
      label: 'Inactive',
      value: 'INACTIVE',
    },
  ];

  const createRow = (selectAll: string[], user: any, index: number, onEdit: any, onDelete: any, onMultiDelete: any) => {
    const all = (
      <Checkbox
        onChange={(e) => onMultiDelete(e, selectAll, (index + 1).toString())}
        checked={selectAll.includes((index + 1).toString()) ? true : false}
      />
    );

    const name = (
      <Typography
        className='cursor-pointer'
        color="primary"
        variant="body1"
        onClick={() => onEdit('view')}
      >
        {user.name}
      </Typography>
    );

    const description = user.description;

    const status = <Switch defaultChecked />;

    const action = (
      <>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => onEdit('edit')}
            color="primary"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => onDelete(index)}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );

    return {
      all,
      id: index + 1,
      name,
      description,
      status,
      action,
    };
  };

  return (
    <>
      {/* Add Data  */}
      <Header
        searchPlaceholder="Search by user name, email and phone"
        onSearch={handleSearch}
        onBtnClick={() => handleManage('new')}
        onDelete={handleMultiDelete}
        isDeleteDisable={state.selectAll.length ? false : true}
        searchInput
        btnText="Create New Role"
      >
        <FormControl
          className="ml-2"
          size="small"
        >
          <Select
            size="small"
            defaultValue="ALL"
          >
            {statusFilter.map((filter) => (
              <MenuItem value={filter.value}>{filter.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Header>

      {/* Manage Data  */}
      <Manage
        isOpen={state.manage.isOpen}
        type={state.manage.type}
        onClose={() => handleManage(state.manage.type)}
      />
      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={state.list}
          height="calc(100vh - 193px)"
          errorMessage="Add user to see the data here"
          pagination={{
            page: 3,
            totalPages: 5,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete User"
        description="Are you sure do you want to delete this user?"
      />

      {/* Multi Delete Data  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMultiDelete()}
        onConfirm={onDelete}
        title="Delete Users"
        description="Are you sure do you want to delete all selected users?"
      />
    </>
  );
};

export default Roles;

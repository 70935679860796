import './style.scss';
import { Box, Button, Grid, TextField } from '@mui/material';

const ChangePassword = () => {
  return (
    <div id='change-password'>
      <Grid container spacing={5}>
        <Grid item xs={12} md={12}>
          <TextField
            label='Old Password*'
            variant="outlined"
            
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='New Password*'
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label='Confirm Password*'
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Box className='mt-3' textAlign='end'>
        <Button
          size='medium'
        >
          Save
        </Button>
      </Box>
    </div>
  )
}

export default ChangePassword;
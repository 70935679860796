import { Typography } from '@mui/material';
import React, { FC } from 'react'

interface props {
    name: string;
    count: number;
    select: string;
    onClick: (name: string) => void;
}

const Tag: FC<props> = ({ name, count, select, onClick }) => {
    return (
        <div
            className='count-box'
            style={{
                backgroundColor:
                select === name ? "#DFF6EA" : "",
            }}
            onClick={() =>
                onClick(name)
            }
        >
            <Typography className="cards-title" variant='h6'>{name}</Typography>
            <Typography className="analytics" variant='h4'>
                {count || 0}
            </Typography>
        </div>
    )
}

export default Tag;
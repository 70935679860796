import { ChangeEvent, useEffect, useState } from 'react';
import { Header, CustomTable, WarningDialog, ExportDialog } from '../../components';
import { Box, Checkbox, FormControl, IconButton, MenuItem, Select, Switch, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import TextField from '@mui/material/TextField/TextField';
import Manage from './manage';
import './index.scss';
import EditIcon from '@mui/icons-material/Edit';
import { withPermission } from '../../components/hoc';
import { getStaticTexts, PERMISSIONS } from '../../constants';
import { ItemsCategoryStates } from '../../interfaces/items-category';

const Items = () => {
  const [state, setState] = useState<ItemsCategoryStates>({
    manage: {
      type: 'new',
      isOpen: false,
    },
    add: {
      isOpen: false,
    },
    deleteWarning: false,
    multiDeleteWarning: false,
    _trip: '',
    list: [],
    selectAll: [],
    exportTrip: false,
  });
  const staticTexts = getStaticTexts('en');

  useEffect(() => {
    const data = [
      {
        name: 'Hulk',
        from: 'Hellerup, Hovedstaden, Denmark',
        to: 'Asgard',
        date: '25 Apr, 2023 11:01 PM',
      },
      {
        name: 'Hulk',
        from: 'Olympus',
        to: 'Hellerup, Hovedstaden, Denmark',
        date: '25 Apr, 2023 11:01 PM',
      },
      {
        name: 'Loki',
        from: 'Asgard',
        to: 'Toronto',
        date: '25 Apr, 2023 11:01 PM',
      },
    ];

    const list = data.map((user, index) =>
      createRow(state.selectAll, user, index, handleManage, handleDelete, handleMultipleDelete)
    );
    setState((prevState) => {
      return {
        ...prevState,
        list,
      };
    });
  }, [state.selectAll, state.selectAll.length]);

  const handleSearch = (e: ChangeEvent<HTMLElement>) => {};

  const onPageChange = (e: ChangeEvent<unknown>) => {};

  const handleManage = (type: 'new' | 'edit' | 'view') => {
    setState((prevState) => {
      return {
        ...prevState,
        manage: {
          ...prevState.manage,
          isOpen: !prevState.manage.isOpen,
          type,
        },
      };
    });
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      payload = state.list.map((ele) => ele.id.toString());
    } else {
      payload = [];
    }

    setState((prevState) => {
      return {
        ...prevState,
        selectAll: payload,
      };
    });
  };

  const handleMultipleDelete = (e: ChangeEvent<HTMLInputElement>, selectAll: string[], id: string) => {
    let payload: Array<string> = [];
    if (e.target.checked) {
      payload = selectAll;
      payload.push(id);
    } else {
      payload = selectAll.filter((ele) => ele !== id);
    }

    setState((prevState) => {
      return {
        ...prevState,
        selectAll: payload,
      };
    });
  };

  const handleMultiDelete = () => {
    setState((prevState) => {
      return {
        ...prevState,
        multiDeleteWarning: !prevState.multiDeleteWarning,
      };
    });
  };

  const handleDelete = (_trip: string = '') => {
    setState((prevState) => {
      return {
        ...prevState,
        deleteWarning: !prevState.deleteWarning,
        _trip,
      };
    });
  };

  const onDelete = () => {};

  const columns = [
    {
      id: 'all',
      label: (
        <Checkbox
          onChange={handleSelectAll}
          checked={state.selectAll.length && state.list.length === state.selectAll.length ? true : false}
        />
      ),
    },
    {
      id: 'id',
      label: staticTexts.serialNumber,
    },
    {
      id: 'name',
      label: staticTexts.categoryName,
    },
    {
      id: 'status',
      label: staticTexts.status,
    },
    {
      id: 'date',
      label: staticTexts.createdAt,
    },
    {
      id: 'action',
      label: staticTexts.action,
    },
  ];

  const createRow = (selectAll: string[], trip: any, index: number, onEdit: any, onDelete: any, onMultiDelete: any) => {
    return {
      all: (
        <Checkbox
          onChange={(e) => onMultiDelete(e, selectAll, (index + 1).toString())}
          checked={selectAll.includes((index + 1).toString()) ? true : false}
        />
      ),
      id: index + 1,
      name: (
        <Typography
          className="cursor-pointer"
          color="primary"
          variant="body1"
          onClick={() => onEdit('view')}
        >
          {trip.name}
        </Typography>
      ),
      from: trip.from,
      to: trip.to,
      date: trip.date,
      status: <Switch defaultChecked />,
      action: (
        <>
          <Tooltip title={staticTexts.edit}>
            <IconButton
              onClick={() => onEdit('edit')}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={staticTexts.delete}>
            <IconButton
              onClick={() => onDelete(index)}
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    };
  };

  return (
    <>
      <Header
        searchPlaceholder={staticTexts.searchByCategoryName}
        btnText={staticTexts.addNewCategory}
        onSearch={handleSearch}
        onBtnClick={() => handleManage('new')}
        onDelete={handleMultiDelete}
        isDeleteDisable={state.selectAll.length ? false : true}
      >
        <FormControl
          className="ml-2"
          size="small"
        >
          <Select
            size="small"
            defaultValue="all"
          >
            <MenuItem value="all">{staticTexts.allStatus}</MenuItem>
            <MenuItem value="ACTIVE">{staticTexts.active}</MenuItem>
            <MenuItem value="INACTIVE">{staticTexts.inactive}</MenuItem>
          </Select>
        </FormControl>
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={state.list}
          height="calc(100vh - 193px)"
          errorMessage={staticTexts.addCategoryToSeeData}
          pagination={{
            page: 3,
            totalPages: 5,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Add Data  */}
      {/* <Add
        isOpen={state.add.isOpen}
        onClose={handleAdd}
      /> */}

      {/* Manage Data  */}
      <Manage
        isOpen={state.manage.isOpen}
        type={state.manage.type}
        onClose={() => handleManage(state.manage.type)}
      />

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title={staticTexts.deleteCategory}
        description={staticTexts.areYouSureYouWantToDeleteThisCateogory}
      />

      {/* Multi Delete Data  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMultiDelete()}
        onConfirm={onDelete}
        title={staticTexts.deleteCategories}
        description={staticTexts.areYouSureYouWantToDeleteThisCateogories}
      />
    </>
  );
};

export default withPermission(Items, PERMISSIONS.VIEW_ITEMS_CATEGORY);

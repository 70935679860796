import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

/**
 * Author: Mayank
 * Date: 5 Nov 2024
 * Summary: A permission-based rendering component for managing user access to content.
 * Description:
 * - The `PermissionGuard` component is designed to conditionally render its children based on the user's
 * permissions and role. It retrieves the current user's permissions and role from the Redux
 * store.
 * - If the user has an 'admin' role, all children are rendered, as admins have
 * unrestricted access. For other roles, the component checks if the specified permission
 * is included in the user's permissions.
 * - If the permission is granted, the children are
 * displayed; otherwise, nothing is rendered. This component simplifies permission
 * management across the application by encapsulating access logic in a reusable way.
 */

interface PermissionGuardProps {
  permission: string;
  children: ReactNode;
}

export const PermissionGuard: React.FC<PermissionGuardProps> = ({ permission, children }) => {
  const { permissions, role } = useSelector((state: any) => state.permissions);

  if (role === 'admin' || permissions.includes(permission)) {
    return <>{children}</>;
  }

  return null;
};
